
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import cardSelected from "@/components/card-selected/card-selected.vue"
import { leavingMessage, Comments } from "@/common/message"
import cardMessage from "@/components/card-message/card-message.vue"
import { getArtDetailed, getArtComment, addVisit } from "@/api"
import router from "@/router"
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    cardSelected,
    cardMessage
  },
  setup() {
    const route = useRoute()
    const articleId = ref("")
    const artDetailed = ref({})
    // 加载数据
    const loading = ref(true);
    // 获取评论参数
    const articleParams = {
      artid: "0",
      pagenum: 1,
      pagesize: 5
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 10,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      getArtComment(articleParams).then((res: any) => {
        Comments.value = res.data.data.comments
      })
    }

    // 点赞
    let zanSign = true;
    const zan = () => {
      if (zanSign) {
        addVisit({artid: articleId.value, sign: 1}).then(() => {
          (artDetailed.value as any).zan_num++;
          zanSign = false;
          message.success("点赞成功！");
        });
      } else {
        message.error("你已经点过赞了！");
      }
    }

    onMounted(() => {
      articleId.value = route.query.id as string;
      articleParams.artid = articleId.value;
      leavingMessage.Message.username = "";
      leavingMessage.Message.content = "";
      // 获取文章详细
      getArtDetailed({id: articleId.value}).then((res: any) => {
        if (res.code == 500) {
          return router.push("/");
        }
        loading.value = false;
        artDetailed.value = res.data.data;
        document.title = res.data.data.title + "_木小天博客";
      }).catch(() => {
        return router.push("/");
      })
      // 获取文章评论
      getArtComment(articleParams).then((res: any) => {
        Comments.value = res.data.data.comments;
        pagination.value.total = res.data.data.total;
        addVisit({artid: articleId.value});
      })
    })

    return {
      ...leavingMessage,
      loading,
      artDetailed,
      Comments,
      pagination,
      zan,
      changePage
    }
  }

})
