<template>
  <a-spin :spinning="loading" tip="加载中" :delay="500">
    <div id="article" class="center">
      <div class="left">
        <!-- 文章信息 -->
        <div class="info">
          <div class="title">{{ artDetailed.title }}</div>
          <div class="more-info">
            <span>作者 / {{ artDetailed.author }}</span>
            <span>来源 / {{ artDetailed.source }}</span>
            <span>阅读 / {{ artDetailed.browse_num + 1 }}</span>
            <span>{{ artDetailed.time }}</span>
          </div>
        </div>
        <!-- 文章内容 -->
        <div class="content">
          <div v-highlight class="con" v-html="artDetailed.content"></div>
          <div @click="zan" class="zan-btn">
            <img src="@/assets/img/zan.png" alt="">
          </div>
          <div class="num">{{ artDetailed.zan_num }}</div>
        </div>
        <!-- 发布评论 -->
        <div class="message">
          <div class="title-wrapper">
            <div class="title">评论</div>
          </div>
          <a-form
            ref="ruleForm"
            :model="Message"
            :rules="messageRules"    
          >
            <a-form-item  name="username">
              <a-input placeholder="昵称" v-model:value="Message.username" />
            </a-form-item>
            <a-form-item  name="content">
              <a-textarea placeholder="评论内容" v-model:value="Message.content" />
            </a-form-item>
          </a-form>
          <a-button :loading="loading" @click="publish(artDetailed.id, 0)" class="submit-btn">发布评论</a-button>
        </div>
        <!-- 评论内容 -->
        <div class="new-message">
          <div class="title-wrapper">
            <div class="title">最新评论</div>
          </div>
          <div v-if="Comments.length == 0" class="nothing">这篇文章还没有收到评论，赶紧来评论吧~</div>
          <div v-else>
            <ul>
              <li v-for="item in Comments" :key="item.id">
                <card-message :comment="item"></card-message>
              </li>
            </ul>
            <!-- 评论分页 -->
            <div style="text-align: center;">
              <a-pagination 
                v-model:current="pagination.current" 
                :total="pagination.total" 
                v-model:pageSize="pagination.pagesize"
                show-less-items 
                @change="changePage"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 页面右侧推荐文章 -->
      <div class="right">
        <div class="selected">
          <div class="title-wrapper">
            <div class="title">精选</div>
          </div>
          <card-selected></card-selected>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import cardSelected from "@/components/card-selected/card-selected.vue"
import { leavingMessage, Comments } from "@/common/message"
import cardMessage from "@/components/card-message/card-message.vue"
import { getArtDetailed, getArtComment, addVisit } from "@/api"
import router from "@/router"
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    cardSelected,
    cardMessage
  },
  setup() {
    const route = useRoute()
    const articleId = ref("")
    const artDetailed = ref({})
    // 加载数据
    const loading = ref(true);
    // 获取评论参数
    const articleParams = {
      artid: "0",
      pagenum: 1,
      pagesize: 5
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 10,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      getArtComment(articleParams).then((res: any) => {
        Comments.value = res.data.data.comments
      })
    }

    // 点赞
    let zanSign = true;
    const zan = () => {
      if (zanSign) {
        addVisit({artid: articleId.value, sign: 1}).then(() => {
          (artDetailed.value as any).zan_num++;
          zanSign = false;
          message.success("点赞成功！");
        });
      } else {
        message.error("你已经点过赞了！");
      }
    }

    onMounted(() => {
      articleId.value = route.query.id as string;
      articleParams.artid = articleId.value;
      leavingMessage.Message.username = "";
      leavingMessage.Message.content = "";
      // 获取文章详细
      getArtDetailed({id: articleId.value}).then((res: any) => {
        if (res.code == 500) {
          return router.push("/");
        }
        loading.value = false;
        artDetailed.value = res.data.data;
        document.title = res.data.data.title + "_木小天博客";
      }).catch(() => {
        return router.push("/");
      })
      // 获取文章评论
      getArtComment(articleParams).then((res: any) => {
        Comments.value = res.data.data.comments;
        pagination.value.total = res.data.data.total;
        addVisit({artid: articleId.value});
      })
    })

    return {
      ...leavingMessage,
      loading,
      artDetailed,
      Comments,
      pagination,
      zan,
      changePage
    }
  }

})
</script>

<style lang="scss" scoped>
  #article {
    display: flex;
    justify-content: space-between;

    .left {
      width: 75%;

      .info {
        background: white;
        padding: 20px;
        margin-bottom: 15px;

        .title {
          font-size: 26px;
          color: #333;
          line-height: 42px;
          font-weight: 400;
          margin-bottom: 10px;
        }

        .more-info {
          font-size: 12px;
          color: #666;

          span {
            margin-right: 15px;
          }
        }
      }

      .content {
        border-right: 1px solid #eee;
        background: white;
        padding: 20px;

        .con {
          margin-bottom: 60px;

          ::v-deep(img) {
            max-width: 100% !important;
          }
        }

        .zan-btn {
          cursor: pointer;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          text-align: center;
          font-size: 26px;
          color: #fff;
          background: #409eff;
          margin: 0 auto 5px;
          overflow: hidden;
          background: linear-gradient(270deg,#2254f4,#406dff);
          box-shadow: 0 12px 30px 0 rgba(34,84,244,.2);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 28px;
            height: 28px;
          }
        }

        .num {
          text-align: center;
          font-weight: 600;
          color: #4a4a4a;
          font-size: 14px;
          margin-bottom: 30px;
        }
      }

      .message {
        background: white;
        padding: 20px;
         margin-top: 20px;

        ::v-deep(.ant-col) {
          width: 100%;
        }

        .submit-btn {
          display: block;
          width: 100%;
          height: 40px;
          cursor: pointer;
          border-radius: 5px;
          -moz-border-radius: 5px;
          -webkit-border-radius: 5px;
          background: #2254f4;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
          background: linear-gradient(270deg,#2254f4,#406dff);
          box-shadow: 0 12px 30px 0 rgba(34,84,244,.2);
          user-select: none;
        }
      }

      .new-message {
        margin-top: 20px;
        background: white;
        padding: 20px;

        .nothing {
          font-size: 14px;
          color: #999;
          padding: 30px;
        }

        ul {

          li {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #eee; 
          }
        }
      }
    }

    .right {
      width: 23%;

      .title-wrapper {
        margin-bottom: 15px;
        margin-top: -10px;
        border-bottom: 1px solid #eee;

        .title {
          color: #454545;
          line-height: 40px;
          display: inline-block;
          font-weight: 600;
          border-bottom: 4px solid #2254f4;
        }
      }

      .selected {
        padding: 20px;
        background: white;
        border-radius: 6px;
      }
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    #article {

      .left {
        width: 100%;
      }

      .right {
        display: none;
      }
    }
  }
</style>